import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import GreenHeading from "../components/heading"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SecondaryHeader from "../components/header/header-secondary"
import PriceQuoteForm from "../components/price-quote-form"
import useLinks from "../components/hooks/useLinks"
import device from "../components/device"

const Wrapper = styled.section`
  .secondary-header-wrapper {
    background: var(--darkgrey);
    color: var(--white);
  }
  .content {
    max-width: 1500px;
    width: 70%;
    margin: 5rem auto 10rem auto;
    display: flex;
    ${device.medium`flex-direction: column;`}
    ${device.small`width: var(--spread);`}
  }

  .form-wrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    ${device.medium`margin-top: 4rem;`}
    .form {
      position: relative;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      background: rgb(166, 168, 109, 1);
      padding: 3rem 2rem;
      width: 100%;
      display: inline-block;
      ${device.small`width: 100%;`}
    }
  }
`
const CommonQuestions = styled.div`
  margin: 2rem 0;
  width: 25%;
  font-size: 0.9rem;
  margin-left: 5%;
  ${device.small`width: 100%;`}
`
const Info = styled.div`
  flex: 1;
  .heading-wrapper {
    .heading {
      line-height: 1;
      font-size: 3rem;
      ${device.small`display: block; line-height: 1; font-size: 2.5rem;`}
      .secondary-heading {
        margin-left: 3rem;
      }
    }
  }
  .text {
    margin: 2rem 0;
    width: 70%;
    font-size: 0.9rem;
    ${device.small`width: 100%;`}
  }
  .social-links {
    a {
      margin-right: 1.5rem;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  .phone {
    margin: 2rem 0;
    line-height: 1.6;
    .toll-free,
    .local {
      margin: 1rem 0;
    }
    .type {
      display: block;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 0.8rem;
    }
    .number {
      letter-spacing: 2px;
      color: var(--green);
    }
  }
`

const GetaPriceQuote = ({ data }) => {
  const extLinks = useLinks()
  return (
    <Layout>
      <Helmet>
        <title>{`${data.site.siteMetadata.title} | ${data.wpgraphql.page.seo.title}`}</title>
        <meta
          name="description"
          content={data.wpgraphql.page.seo.opengraphDescription}
        />
        <meta
          property="og:url"
          content={data.wpgraphql.page.seo.opengraphUrl}
        />
        <meta
          property="og:site_name"
          content={data.wpgraphql.page.seo.opengraphSiteName}
        />
        <meta property="og:title" content={data.wpgraphql.page.seo.title} />
        <meta
          property="og:description"
          content={data.wpgraphql.page.seo.opengraphDescription}
        />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Twitter */}
        <meta
          name="twitter:title"
          content={data.wpgraphql.page.seo.twitterTitle}
        />
        <meta
          name="twitter:description"
          content={data.wpgraphql.page.seo.twitterDescription}
        />

        <meta name="geo.region" content="US-PA" />
        <meta name="geo.placename" content="King of Prussia" />
        <meta name="geo.position" content="40.091710;-75.346160" />
        <meta name="ICBM" content="40.091710, -75.346160" />
      </Helmet>
      <Wrapper>
        <div className="secondary-header-wrapper">
          <SecondaryHeader />
        </div>
        <div className="content">
          <Info>
            <div className="heading-wrapper">
              <h1 className="heading">
                <GreenHeading size="6rem" text="Get" />
                <br />
                <span className="secondary-heading">a Quote</span>
              </h1>
            </div>
            <div className="form-wrapper">
              <div className="form">
                <PriceQuoteForm />
              </div>
            </div>
          </Info>
          <CommonQuestions
            dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}
          />
        </div>
      </Wrapper>
    </Layout>
  )
}

export default GetaPriceQuote

export const query = graphql`
  {
    wordpressPage(slug: { eq: "get-a-price-quote" }) {
      content
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    wpgraphql {
      page(id: "5770", idType: DATABASE_ID) {
        nexvelschemapagesposts {
          videos {
            title
            url
            description
            thumbnailImage {
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
            }
            uploadDate
          }
          questionsAndAnswers {
            question
            answer
          }
          maps {
            mapUrl
          }
          digitaldocuments {
            title
          }
          images {
            image {
              date
              description
              title
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
            }
          }
          areaServedLandingPageSchema
          serviceSchema {
            serviceNameSchema
            linkToRelevantServicePageSchema
          }
          keywordsSchema {
            keywordSchema
          }
          localAwardsSchema {
            localAwardSchema
          }
          latitudeSchema
          longitudeSchema
          relevantExternalPagesSchema {
            relevantExternalPageSchema
          }
        }
        seo {
          title
          opengraphDescription
          opengraphUrl
          opengraphSiteName
          twitterTitle
          twitterDescription
        }
      }
    }
    wordpressAcfOptions {
      options {
        cities {
          city
        }
        services {
          service
          link_to_relevant_page_on_your_website
        }
      }
    }
  }
`
